import React, {useEffect, useState} from 'react';
import logo from "../../../assets/images_custom/img_logo_clicspy_login.png";
import InputComponent from "../../../components/InputComponent";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlined';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import {validateEmail} from '../../../services/ValidationSvc'
import RequestSvc from '../../../services/RequestSvc';
import {URL_LOGIN} from '../../../utils/Constants';
import {URL_DASHBOARD} from '../../../utils/Constants';

/**
 * @author Hags
 * Formulario para hacer login en la plataforma
 * @returns {Element}
 * @constructor
 */
export default function LoginForm() {

    let [email, setEmail] = useState("");
    let [password, setPassword] = useState("");
    let [isChecked, setIsChecked] = useState(false);
    let [errorLogin, setErrorLogin] = useState(false);
    let [errorMessage, setErrorMessage] = useState();

    useEffect(() => {
        if (localStorage.checkbox && localStorage.email !== "") {
            setEmail(localStorage.email)
            setIsChecked(true)
        }
    }, []);

    /*  INIT - EVENTS */

    const onClickForgotPasswordBtn = () => {
        alert("Implement password recovery")
    }

    const onClickUnlockAccessBtn = () => {
        alert("Implement create account")
    }

    const onChangeRememberMe = (event) => {
        setIsChecked(event.target.checked)
    }

    const submit = (event) => {
        if (isChecked && email !== "") {
            localStorage.setItem("email", email)
            localStorage.setItem("checkbox", isChecked)
        } else {
            localStorage.removeItem("email")
            localStorage.removeItem("checkbox")
        }

        let isValidForm = validateBeforeSubmit();
        if (isValidForm) {
            tryLogin()
        }
    }

    /**
     * Validar si el formulario es valido o no.
     * @returns {boolean}
     */
    const validateBeforeSubmit = () => {
        return !(email === "" || password === "");
    }

    /**
     * Hacer el submit para loguearse
     * @returns {Promise<void>}
     */
    const tryLogin = async () => {
        let svc = new RequestSvc();
        // Agregar los header para el request.
        let headers = new Headers();
        headers.append('Authorization', 'Basic ' + btoa(`${email}:${password}`));
        let result = await svc.get(URL_LOGIN, headers);
        if (result.error) {
            setErrorLogin(result.error)
            setErrorMessage(result.messages)
            return;
        }
        window.location.href = URL_DASHBOARD + result.data.token
    }


    return (<div className={"login-form-layout"}>
        {errorLogin && (<span className='login-form-error-message'>Tu sesión a caducado. Logueate nuevamente</span>)}
        <img src={logo} alt={"imgBackground"} className={"login-container-img-logo"}/>
        <p className={"login-form-welcome-text"}>Bienvenido Espía</p>
        <p className={"login-secondary-text"} style={{marginBottom: 24}}>Inicia sesión para continuar</p>
        <InputComponent
            placeholder={"Correo electrónico"}
            className={"login-input"}
            value={email}
            leftIcon={<PersonOutlineOutlinedIcon/>}
            type={"email"}
            onChange={(text) => setEmail(text)}
            validateMethod={validateEmail}
            error={setErrorLogin}
        />
        <InputComponent
            placeholder={"Contraseña"}
            className={"login-input"}
            leftIcon={<HttpsOutlinedIcon/>}
            rightIcon={<VisibilityOutlinedIcon/>}
            type={"password"}
            onChange={(text) => setPassword(text)}
            error={setErrorLogin}
        />
       
        <div className={"login-container-remember-me"}>
            <input style={{float: "left"}}
                   type={"checkbox"}
                   name={"rememberMe"}
                   id={"rememberMe"}
                   checked={isChecked}
                   onChange={onChangeRememberMe}/>
            <label className={"login-label-remember-me"}>Acuérdate de mí</label>
        </div>
        <button className={"login-button"}
                onClick={submit}>Entrar
        </button>
        <button
            className={"login-button-type-label-unlock-access"}
            onClick={onClickUnlockAccessBtn}>¿No tienes cuenta? Desbloquea tu acceso ahora
        </button>
        {errorLogin && (<span className='login-form-error-message'>{errorMessage}</span>)}
    </div>);
}