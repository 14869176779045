import React, { useState } from 'react';
import "./loginPage.css"
import LoginForm from "./loginForm/LoginForm";
import LoginRightLayout from "./loginRightLayout/LoginRightLayout";
import ForgotForm from "./forgotForm/ForgotForm";
import { useSearchParams } from 'react-router-dom'

export default function LoginPage() {
    
    const [showForgotForm, setShowForgotForm] = useState(false);
    const [showExpiredTokenMessage, setShowExpiredTokenMessage] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const token = searchParams.get('token')

    if (token === 'expired' && !showExpiredTokenMessage) {
        setShowExpiredTokenMessage(true);
      }




    const handleForgotPasswordClick = () => {
        setShowForgotForm(true);
    };

    const handleGoToLoginClick = () => {
        setShowForgotForm(false);
    };

    return (
        <>
        <div className={"login-main-layout"}>
            
            {showForgotForm ? (
                // Display ForgotForm if showForgotForm is true
              
            <div className={"forms-container"}>
                <ForgotForm onClose={() => setShowForgotForm(false)} />
                <button className={"GoToLogin-button"} onClick={handleGoToLoginClick}>
                    Ir al Login
                </button>
            </div>
              
              
                
            ) : (
                // Display LoginForm and other components if showForgotForm is false
                <div className={"forms-container"}>
                    
                    {showExpiredTokenMessage && (
                        <div className={"alert-box"}>
                            Tu sesión ha caducado.<br/>
                            Loguéate de nuevo
                        </div>
                    )}

                    <LoginForm />
                    <button className={"forgot-button"} onClick={handleForgotPasswordClick}>
                        Olvidé Mi Clave
                    </button>
                </div>
            )}
            <LoginRightLayout />
        </div>
    </> 
    );
}