import React, {useState, useRef} from 'react';
import logo from "../../../assets/images_custom/img_logo_clicspy_login.png";
import InputComponent from "../../../components/InputComponent";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlined';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import {validateEmail} from '../../../services/ValidationSvc'
import RequestSvc from '../../../services/RequestSvc';
import {URL_FORGOT} from '../../../utils/Constants';


/**
 * @author Hags
 * Formulario para hacer login en la plataforma
 * @returns {Element}
 * @constructor
 */
export default function ForgotForm() {
    const emailRef = useRef('');

    let [email, setEmail] = useState("");
    
    let [hasCompleted, setHasCompleted] = useState(false);
    let [message, setMessage] = useState("");
    let [errorForgot, setErrorForgot] = useState(false);
    let [errorMessage, setErrorMessage] = useState(false);
    

    

    /*  INIT - EVENTS */

 
    const submit = (event) => {
        
        let isValidForm = validateBeforeSubmit();
        if (isValidForm) {
            const payload = {
                email: email
            };
           
            tryForgot(payload)

        }
    }

    /**
     * Validar si el formulario es valido o no.
     * @returns {boolean}
     */
    const validateBeforeSubmit = () => {
        return !(email === "");
    }

    /**
     * Hacer el submit para reestablecer contraseña
     * @returns {Promise<void>}
     */
    const tryForgot = async (payload) => {
        
        let svc = new RequestSvc();

        let result = await svc.post(URL_FORGOT, payload);
        console.log(result.data.data)
        setHasCompleted(true)
        setMessage(result.data.data)
        
        if (result.code != 200) {
            
            setErrorForgot(true)
            setErrorMessage(result.message)
            
            return;
        }
       
    }


    return (<div className={"forgot-form-layout"}>
        <img src={logo} alt={"imgBackground"} className={"login-container-img-logo"}/>
        <p className={"login-form-welcome-text"}> Espía, Olvidaste la clave</p>
        <p className={"login-secondary-text"} style={{marginBottom: 24}}>Escribe tu Email para continuar</p>
        <InputComponent
            placeholder={"Correo electrónico"}
            className={"login-input"}
            value={email}
            leftIcon={<PersonOutlineOutlinedIcon/>}
            type={"email"}
            onChange={(text) => setEmail(text)}
            validateMethod={validateEmail}
            error={setErrorForgot}
        />
        
        <button className={"login-button"}
                onClick={submit}>Reestablecer Mi Clave
        </button>
        
        {errorForgot && (<span className='login-form-error-message'>{errorMessage}</span>)}
        {hasCompleted && (<span className='login-form-error-message'>{message}</span>)}
        
    </div>);
}