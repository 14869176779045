import React, {useState} from "react";
import IconButton from '@mui/material/IconButton';

export default function InputComponent
    (
        {
            className,
            placeholder,
            leftIcon,
            rightIcon,
            type = 'text',
            value,
            onChange,
            validateMethod = () => {
            },
            error
        }
    ) {

    const [isValid, setIsValid] = useState(true);
    const [showPassword, setShowPassword] = React.useState(false);

    /*  INIT - EVENTS   */
    const handleClickShowPassword = () => setShowPassword((show) => {
        if (type === 'password') {
            return !show
        }
    });

    const onFocusEvent = (event) => {
        error(false)
    }
    const onChangeInput = (event) => {
        const inputValue = event.target.value;
        if (validateMethod && inputValue !== '') {
            const resultValidate = validateMethod(inputValue)
            setIsValid(resultValidate);
        }
        if (onChange) {
            onChange(inputValue)
        }
        if (inputValue === '') {
            setIsValid({isValid: true, msg: ""})
        }
    }

    /*  END - EVENTS   */

    return (
        <div className={"login-input-main-layout"}>
            <div className={"login-wrapper-input"}>
                {leftIcon && (
                    <IconButton className='login-input-left-icon'>
                        {leftIcon}
                    </IconButton>
                )}
                <input
                    className={className}
                    placeholder={placeholder}
                    type={showPassword ? 'text' : type}
                    value={value}
                    onChange={onChangeInput}
                    onFocus={onFocusEvent}
                />
                {rightIcon && (
                    <IconButton className='login-input-right-icon' onClick={handleClickShowPassword}>
                        {rightIcon}
                    </IconButton>
                )}
            </div>
            {!isValid?.isValid && (
                <span className='login-input-error'>{isValid?.msg}</span>
            )}
        </div>
    );
}