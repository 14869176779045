module.exports = class ValidationSvc {
    /**
     * Validar si el texto es un email
     * @param email
     * @returns {{msg: string, isValid: boolean}}
     */
    static validateEmail(email) {
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!email || regex.test(email) === false) {
            return {isValid: false, msg: "Debe ingresar un email."};
        }
        return {isValid: true, msg: "Email is valid"};
    }
}