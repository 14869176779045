/**
 * @author Hags
 * Clase encargada de realizar las peticiones HTTP
 * @type {RequestSvc}
 */
module.exports = class RequestSvc {

    /**
     * Hacer una petición de tipo POST
     * @param endpoint Endpoint al que se hará la petición
     * @param payload Datos que se enviarán en la petición
     * @param header Headers que se desean enviar en la petición
     * @returns {Promise<{}>}
     */
    post = async (endpoint, payload, header = []) => {
        let headersToRequest = header;
        if (header.length === 0) {
            headersToRequest = new Headers();
        }
        headersToRequest.append("Content-Type", "application/json");
        const requestOptions = {
            method: 'POST',
            headers: headersToRequest,
            redirect: 'follow',
            body: JSON.stringify(payload)
        }
        let requestResponse = await fetch(endpoint, requestOptions);
        return await this.processData(requestResponse);
    }

    /**
     * Hacer una petición de tipo GET
     * @param endpoint Endpoint al que se hará la petición
     * @param header Headers que se desean enviar en la petición
     * @returns {Promise<{}>}
     */
    get = async (endpoint, header = {}) => {
        const requestOptions = {
            method: 'GET',
            headers: header ?? null,
            redirect: 'follow'
        }
        let requestResponse = await fetch(endpoint, requestOptions);
        return await this.processData(requestResponse);
    }

    /**
     * Procesar la respuesta de cada petición.
     * @param requestResponse
     * @returns {Promise<{}>}
     */
    processData = async (requestResponse) => {
        let structResponse = {}
        try {
            structResponse.status_code = requestResponse.status
            structResponse.data = await requestResponse.json();
            if (structResponse.data.error) {
                let errorStruct = {};
                errorStruct.code = 401;
                errorStruct.messages = structResponse.data.msg;
                throw errorStruct;
            }
        } catch (err) {
            structResponse.error = true;
            structResponse.messages = err.messages ?? 'Unexpected error';
            structResponse.code = err.code ?? 500;
            structResponse.status_code = err.code ?? 500;
        }
        return structResponse;
    }

}