import {Route, Routes} from "react-router-dom";
import './App.css';
import LoginPage from "./views/login/LoginPage";

function App() {
  return (
      <div className={"main-layout-app"}>
          <Routes>
              <Route path={"/login"} element={<LoginPage/>}/>
          </Routes>
      </div>
  );
}

export default App;
